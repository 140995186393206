import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { login } from '../api';
import earth from '../images/earth.png';
import logo from '../images/logologin.png';
import './Login.css';

function Login(props) {
    const { setCompanyName, setLogin, handleLanguageChange, language, translation } = props;

    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;

        login(email, password)
            .then((data) => {
                if (data && data.token) {
                    localStorage.setItem('token', data.token);
                    // localStorage.setItem('companyName', data.companyName);
                    setCompanyName(data.company_name);
                    setLogin(true);
                    setLoading(false);
                    Navigate('/overview');
                } else {
                    setLoading(false);
                    alert('Login failed');
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                alert('Login failed');
            });
    };

    return (
        <div style={{ height: '100vh', minWidth: '1000px', minHeight: '40rem', display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-around', verticalAlign: 'center' }}>
            {/*<h1>Login</h1>*/}
            {/* <img src={logo} style={{width: '510px', height: '510px',paddingTop: '150px', backgroundColor: '#F323423'}}/> */}
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <div style={{ width: '28.5rem', height: '30rem', display: 'flex', flexFlow: 'column nowrap', backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center', borderRadius: '16px', position: 'relative' }}>
                        <div style={{ width: '100%', display: 'flex', textAlign: 'center', alignItems: 'center' }}>
                            <img src={earth} style={{ width: '1rem', marginLeft: 'auto' }} />
                            <FormControl sx={{ m: 0, minWidth: 60 }} size='small'>
                                <Select
                                    native
                                    value={language}
                                    onChange={(event) => {
                                        handleLanguageChange(event.target.value);
                                    }}
                                    inputProps={{
                                        name: 'language',
                                        id: 'uncontrolled-native',
                                    }}
                                    style={{ fontSize: '10px', margin: '0px', display: 'inline-block', float: 'right', fontFamily: 'Roboto', fontWeight: '600' }}
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                >
                                    <option value='english'>English</option>
                                    <option value='chinese'>中文</option>
                                    <option value='japanese'>日本語</option>
                                </Select>
                            </FormControl>
                        </div>
                        <img src={logo} style={{ width: '12.5rem', position: 'absolute', top: '2rem' }} />
                        <form onSubmit={onSubmit} style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', marginTop: '5rem' }}>
                            <label className='login' htmlFor='email' style={{ width: '21rem' }}>
                                {translation.email}
                            </label>
                            <input className='login' id='email' type='text' placeholder={translation.email} name='email' />
                            <label className='login' htmlFor='password' style={{ width: '21rem' }}>
                                {translation.password}
                            </label>
                            <input className='login' id='password' type='password' placeholder={translation.password} />
                            <div style={{ width: '21rem', marginBottom: '4rem' }}>
                                <input type='checkbox' style={{ margin: '5px 0 10px 0' }} />
                                <label style={{ marginLeft: '5px' }}>{translation.rememberMe}</label>
                                <Link to='/forgot' style={{ float: 'right' }}>
                                    {translation.forgotPassword}
                                </Link>
                            </div>
                            {/* <input className='login' type='submit' value='Login' style={{ width: '9.25rem', height: '3rem', margin: '5px', borderRadius: '1.5rem' }} /> */}
                            {/* <input className='login' type="button" value="Forgot password" style={{width: '392px', height: '36px', margin: '5px', borderRadius: '8px'}}/> */}
                            <button className='signup blue' type='submit' style={{ width: '9.25rem' }}>
                                {translation.login}
                            </button>
                        </form>
                        <div style={{ position: 'absolute', bottom: '0' }}>
                            <p>
                                {translation.noAccount} <Link to='/signup'>{translation.signupForFree}</Link>
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Login;
