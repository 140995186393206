import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { getInformation } from '../api';
import { translations } from '../meta/translations';
import './Common.css';
import Download from './Download';
import Forgot from './Forgot';
import RealTime from './RealTime';
import Information from './Information';
import Login from './Login';
import Overview from './Overview';
import Pending from './Pending';
import Records from './Records';
import Reset from './Reset';
import Signup from './Signup';
import Verify from './Verify';
import Header from './components/Header';
import MenuBar from './components/MenuBar';
import ProtectedRoute from './components/ProtectedRoute';
import Report from './components/Report';
import { temperatureContext } from '../lib/context';

function Home() {
    const [language, setLanguage] = useState('english');
    const [tempUnit, setTempUnit] = useState('celcius');
    const [info, setInfo] = useState({});
    const [color, setColor] = useState('light');
    const [companyName, setCompanyName] = useState('');
    const [translation, setTranslation] = useState(translations['english']);
    const [login, setLogin] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getInformation().then((data) => {
                console.log(data);
                if (data && data.company && data.user) {
                    setInfo({ ...data.company, ...data.user });
                    setCompanyName(data.company.display_name);
                    setTempUnit(data.user.preference.temp_unit);
                    setLanguage(data.user.preference.language);
                    setTranslation(translations[data.user.preference.language]);
                }
            });
        }
    }, [login, tempUnit]);
    // Load multiple time. Any way to solve this?

    function handleCompanyNameChange(name) {
        setCompanyName(name);
    }

    function handlePreferenceChange(language, temp_unit) {
        setLanguage(language);
        setTranslation(translations[language]);
        setTempUnit(info.preference.tempUnit);
        setInfo({ ...info, preference: { ...info.preference, language, temp_unit } });
    }

    function handleLanguageChange(language) {
        setLanguage(language);
        setTranslation(translations[language]);
    }

    return (
        <div className='Home' style={{ minWidth: '1000px', backgroundColor: color === 'light' ? '#F3F5F9' : '#262729' }}>
            <temperatureContext.Provider value={tempUnit}>
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Navigate to='/overview' />} />
                        <Route path='/login' element={<Login setCompanyName={setCompanyName} setLogin={setLogin} language={language} translation={translation.Login} handleLanguageChange={handleLanguageChange} />} />
                        <Route path='/forgot' element={<Forgot setCompanyName={setCompanyName} setLogin={setLogin} language={language} translation={translation.Login} />} />
                        <Route path='/signup' element={<Signup setCompanyName={setCompanyName} setLogin={setLogin} language={language} translation={translation.Login} handleLanguageChange={handleLanguageChange} />} />
                        <Route path='/verify' element={<Verify translation={translation.Login} handleLanguageChange={handleLanguageChange} />} />
                        <Route path='/reset' element={<Reset translation={translation.Login} handleLanguageChange={handleLanguageChange} />} />
                        <Route
                            path='/overview'
                            element={
                                <ProtectedRoute>
                                    <div>
                                        <MenuBar color={color} setColor={setColor} translation={translation} />
                                        <div style={{ marginLeft: '75px' }}>
                                            <Header title={translation.Overview.title} companyName={companyName} logout={translation.Login.logout} language={language} setLogin={setLogin} color={color} />
                                            <Overview translation={translation.Overview} />
                                        </div>
                                    </div>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/pending'
                            element={
                                <ProtectedRoute>
                                    <div>
                                        <MenuBar color={color} setColor={setColor} translation={translation} />
                                        <div style={{ marginLeft: '75px' }}>
                                            <Header title={translation.Pending.title} companyName={companyName} logout={translation.Login.logout} language={language} setLogin={setLogin} color={color} />
                                            <Pending translation={translation.Pending} />
                                        </div>
                                    </div>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/uploaded'
                            element={
                                <ProtectedRoute>
                                    <div>
                                        <MenuBar color={color} setColor={setColor} translation={translation} />
                                        <div style={{ marginLeft: '75px' }}>
                                            <Header title={translation.Records.title} companyName={companyName} logout={translation.Login.logout} language={language} setLogin={setLogin} color={color} />
                                            <Records translation={translation.Records} />
                                        </div>
                                    </div>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/realtime'
                            element={
                                <ProtectedRoute>
                                    <div>
                                        <MenuBar color={color} setColor={setColor} translation={translation} />
                                        <div style={{ marginLeft: '75px' }}>
                                            <Header title={translation.RealTime.title} companyName={companyName} logout={translation.Login.logout} language={language} setLogin={setLogin} color={color} />
                                            <RealTime translation={translation.RealTime} />
                                        </div>
                                    </div>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/setting'
                            element={
                                <ProtectedRoute>
                                    <div>
                                        <MenuBar color={color} setColor={setColor} translation={translation} />
                                        <div style={{ marginLeft: '75px' }}>
                                            <Header title={translation.Information.title} companyName={companyName} logout={translation.Login.logout} language={language} setLogin={setLogin} color={color} />
                                            <Information translation={translation.Information} language={language} handlePreferenceChange={handlePreferenceChange} info={info} handleCompanyNameChange={handleCompanyNameChange} />
                                        </div>
                                    </div>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/report'
                            element={
                                <ProtectedRoute>
                                    <div>
                                        <MenuBar color={color} setColor={setColor} translation={translation} />
                                        <div style={{ marginLeft: '75px' }}>
                                            <Header title='報告 Report' />
                                            <Report />
                                        </div>
                                    </div>
                                </ProtectedRoute>
                            }
                        />
                        <Route path='/download' element={<Download />} />
                    </Routes>
                </BrowserRouter>
            </temperatureContext.Provider>
        </div>
    );
}

export default Home;
