import dayjs from 'dayjs';
import ExcelJs from 'exceljs';

function createData(time, temp) {
    return { time, temp };
}

function generateExcel(excelData) {
    const lineColor = excelData.is_normal ? '4485C6' : 'EA3323';
    const tempSymbol = excelData.tempUnit === 'fahrenheit' ? '°F' : '°C';

    const tempData = excelData.detailRowData
        ? excelData.detailRowData.map((row) => {
                return createData(dayjs(row.time).format('YYYY-MM-DD HH:mm'), row.temperature);
            })
        : [];

    const duration = tempData.length * 10;
    const durationD = Math.floor(duration / 60 / 24);
    const durationH = Math.floor((duration % (60 * 24)) / 60);
    const durationM = duration % 60;
    const durationAboveThreshold = tempData.filter((row) => row.temp > excelData.upperLimit).length * 10;
    const durationAboveThresholdD = Math.floor(durationAboveThreshold / 60 / 24);
    const durationAboveThresholdH = Math.floor((durationAboveThreshold % (60 * 24)) / 60);
    const durationAboveThresholdM = durationAboveThreshold % 60;
    const durationBelowThreshold = tempData.filter((row) => row.temp < excelData.lowerLimit).length * 10;
    const durationBelowThresholdD = Math.floor(durationBelowThreshold / 60 / 24);
    const durationBelowThresholdH = Math.floor((durationBelowThreshold % (60 * 24)) / 60);
    const durationBelowThresholdM = durationBelowThreshold % 60;
    const durationOutOfThreshold = excelData.timeLimit / 60;
    const durationOutOfThresholdD = Math.floor(durationOutOfThreshold / 60 / 24);
    const durationOutOfThresholdH = Math.floor((durationOutOfThreshold % (60 * 24)) / 60);
    const durationOutOfThresholdM = durationOutOfThreshold % 60;
    const intervalD = Math.floor(excelData.interval / 60 / 60 / 24);
    const intervalH = Math.floor(excelData.interval / 60 / 60) % 24;
    const intervalM = Math.floor(excelData.interval / 60) % 60;

    const maxTemp = Math.max(...tempData.map((row) => row.temp));
    const minTemp = Math.min(...tempData.map((row) => row.temp));

    const workbook = new ExcelJs.Workbook();
    const information = workbook.addWorksheet('Information', { style: { font: { name: 'Arial', size: 10 } } });
    const dataRecords = workbook.addWorksheet('Data Records');

    information.getColumn(1).width = 15;
    information.getColumn(3).width = 15;
    information.getColumn(5).width = 15;
    information.getColumn(7).width = 15;

    information.getColumn(1).style = { font: { name: 'Calibri', size: 12, bold: false }, alignment: { vertical: 'middle', horizontal: 'left' } };
    information.getColumn(3).style = { font: { name: 'Calibri', size: 12, bold: false }, alignment: { vertical: 'middle', horizontal: 'left' } };
    information.getColumn(5).style = { font: { name: 'Calibri', size: 12, bold: false }, alignment: { vertical: 'middle', horizontal: 'left' } };
    information.getColumn(7).style = { font: { name: 'Calibri', size: 12, bold: false }, alignment: { vertical: 'middle', horizontal: 'left' } };

    information.getCell('A3').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F2D69' } };
    information.getCell('A6').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1C50A3' } };
    information.getCell('A9').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1C50A3' } };
    information.getCell('A15').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1C50A3' } };

    information.getCell('A3').font = { size: 24, color: { argb: 'FFFFFF' } };
    information.getCell('A6').font = { size: 18, color: { argb: 'FFFFFF' } };
    information.getCell('A9').font = { size: 18, color: { argb: 'FFFFFF' } };
    information.getCell('A15').font = { size: 18, color: { argb: 'FFFFFF' } };
    information.getCell('C12').font = { size: 12, color: { argb: lineColor } };

    information.getColumn(1).values = [`File created: ${dayjs().format('YYYY-MM-DD HH:mm')}`, '', 'Data Report', 'Period covered by this report', 'Time Zone', 'Device Information', 'Device Model', 'Device ID', 'Data Details', 'Number of Records', 'Duration of Records', 'Status', '', 'Excursion (Fail) Alarm', 'Shipment Information', 'Shipment Number', 'Carrier', 'Start Location', 'Note'];

    information.getColumn(3).values = ['', '', '', '', '', '', excelData.model_info?.model, excelData.id, '', excelData.number_of_record, `${durationD}D ${durationH}H ${durationM}M`, excelData.is_normal ? 'Pass' : 'Fail', '', `${excelData.thresholdType}: Out of ${excelData.upperLimit}${tempSymbol} to ${excelData.lowerLimit}${tempSymbol} for ${durationOutOfThresholdD} D ${durationOutOfThresholdH} H ${durationOutOfThresholdM} M`, '', excelData.meta_data.shipment_number, excelData.meta_data.carrier_name, excelData.meta_data.start_location, excelData.meta_data.note];

    information.getColumn(5).values = ['', '', '', `${excelData.startTime} to ${excelData.endTime}`, excelData.timeZone, '', 'Sensor Type', 'Logging Interval', '', 'Maximum Temperature', 'Minimum Temperature', `Duration Over ${excelData.upperLimit}${tempSymbol}`, `Duration Below ${excelData.lowerLimit}${tempSymbol}`, '', '', 'Shipper', 'Consignee', 'End Location'];

    information.getColumn(7).values = ['', '', '', '', '', '', 'Temperature', `${intervalD}D ${intervalH}H ${intervalM}M`, '', `${maxTemp}${tempSymbol}`, `${minTemp}${tempSymbol}`, `${durationAboveThresholdD}D ${durationAboveThresholdH}H ${durationAboveThresholdM}M`, `${durationBelowThresholdD}D ${durationBelowThresholdH}H ${durationBelowThresholdM}M`, '', '', excelData.meta_data.shipper_name, excelData.meta_data.consignee_name, excelData.meta_data.end_location];

    information.mergeCells('A1:D1');
    information.mergeCells('A3:H3');
    information.mergeCells('A4:D4');
    information.mergeCells('E4:H4');
    information.mergeCells('A5:D5');
    information.mergeCells('E5:H5');
    information.mergeCells('A6:H6');
    information.mergeCells('A7:B7');
    information.mergeCells('C7:D7');
    information.mergeCells('E7:F7');
    information.mergeCells('G7:H7');
    information.mergeCells('A8:B8');
    information.mergeCells('C8:D8');
    information.mergeCells('E8:F8');
    information.mergeCells('G8:H8');
    information.mergeCells('A9:H9');
    information.mergeCells('A10:B10');
    information.mergeCells('C10:D10');
    information.mergeCells('E10:F10');
    information.mergeCells('G10:H10');
    information.mergeCells('A11:B11');
    information.mergeCells('C11:D11');
    information.mergeCells('E11:F11');
    information.mergeCells('G11:H11');
    information.mergeCells('A12:B13');
    information.mergeCells('C12:D13');
    information.mergeCells('E12:F12');
    information.mergeCells('G12:H12');
    information.mergeCells('E13:F13');
    information.mergeCells('G13:H13');
    information.mergeCells('A14:B14');
    information.mergeCells('C14:H14');
    information.mergeCells('A15:H15');
    information.mergeCells('A16:B16');
    information.mergeCells('C16:D16');
    information.mergeCells('E16:F16');
    information.mergeCells('G16:H16');
    information.mergeCells('A17:B17');
    information.mergeCells('C17:D17');
    information.mergeCells('E17:F17');
    information.mergeCells('G17:H17');
    information.mergeCells('A18:B18');
    information.mergeCells('C18:D18');
    information.mergeCells('E18:F18');
    information.mergeCells('G18:H18');
    information.mergeCells('A19:B19');
    information.mergeCells('C19:H19');

    information.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center' };
    information.getCell('A6').alignment = { vertical: 'middle', horizontal: 'center' };
    information.getCell('A9').alignment = { vertical: 'middle', horizontal: 'center' };
    information.getCell('A15').alignment = { vertical: 'middle', horizontal: 'center' };
    information.getCell('A12').alignment = { vertical: 'middle', horizontal: 'left' };
    information.getCell('C12').alignment = { vertical: 'middle', horizontal: 'left' };

    dataRecords.getColumn(1).style = { font: { name: 'Calibri', size: 14, bold: false }, alignment: { vertical: 'middle', horizontal: 'left' } };
    dataRecords.getColumn(2).style = { font: { name: 'Calibri', size: 14, bold: false }, alignment: { vertical: 'middle', horizontal: 'left' } };
    dataRecords.getColumn(3).style = { font: { name: 'Calibri', size: 14, bold: false }, alignment: { vertical: 'middle', horizontal: 'left' } };

    dataRecords.getColumn(1).width = 20;
    dataRecords.getColumn(2).width = 10;
    dataRecords.getColumn(3).width = 10;

    dataRecords.getColumn(1).values = ['Date', ...tempData.map((row) => dayjs(row.time).format('YYYY-MM-DD'))];
    dataRecords.getColumn(2).values = ['Time', ...tempData.map((row) => dayjs(row.time).format('HH:mm'))];
    dataRecords.getColumn(3).values = [tempSymbol, ...tempData.map((row) => row.temp)];

    // download the file
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Data-report-${excelData.id}.xlsx`;
        a.click();
    });
}

export default generateExcel;
