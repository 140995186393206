import Plot from 'react-plotly.js';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useContext } from 'react';
import { temperatureContext } from '../../lib/context';

function Detail(props) {
    const { details, row, translation, maxTemp, minTemp, bgColor } = props;

    const tempUnit = useContext(temperatureContext);

    const columns = [
        { id: 'time', label: translation.time, minWidth: 60 },
        { id: 'temperature', label: translation.temp, minWidth: 60 },
    ];


    const normalDrawX = details.length > 432 ? details.filter((item) => item.temperature <= row.upperLimit && item.temperature >= row.lowerLimit).filter((item, index) => index % 6 === 0).map((item) => item.time) : details.filter((item) => item.temperature <= row.upperLimit && item.temperature >= row.lowerLimit).map((item) => item.time);
    const normalDrawY = details.length > 432 ? details.filter((item) => item.temperature <= row.upperLimit && item.temperature >= row.lowerLimit).filter((item, index) => index % 6 === 0).map((item) => item.temperature) : details.filter((item) => item.temperature <= row.upperLimit && item.temperature >= row.lowerLimit).map((item) => item.temperature);

    return (
        <>
                    <div style={{width: '40%'}}>
                        <div style={{fontSize: '16px', textAlign: 'center', margin: '20px auto'}}>{translation.tempRecordList}</div>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                sx={{bgcolor: '#E0EDFB'}}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {details.map((row) => {
                                        // console.log(row)
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.time}>
                                                {columns.map((column, index) => {
                                                    const value = row[column.id];
                                                    if (index === 1) {
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {/* {column.format && typeof value === 'number'
                                                                    ? column.format(value)
                                                                    : `${value}°C`} */}
                                                                    {(value !== null && value !== "") ? `${value}${tempUnit === 'fahrenheit' ? '°F' : '°C'}` : ''}
                                                            </TableCell>
                                                        )
                                                    }
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : `${value}`}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <Plot
                        data={[
                            {
                                x: details.map((row) => row.time),
                                y: details.map((row) => row.temperature),
                                type: 'scatter',
                                mode: 'lines',
                                marker: {color: '#E6E6FF'}, //#e6e6ff
                                showlegend: false,
                            },
                            {
                                x: normalDrawX,
                                y: normalDrawY,
                                type: 'scatter',
                                mode: 'markers',
                                marker: {color: '#3F8EE8'},
                                name: translation.normal,
                            },
                            {
                                x: details.filter((item) => item.temperature > row.upperLimit || item.temperature < row.lowerLimit).map((item) => item.time),
                                y: details.filter((item) => item.temperature > row.upperLimit || item.temperature < row.lowerLimit).map((item) => item.temperature),
                                type: 'scatter',
                                mode: 'markers',
                                marker: {color: '#FF3838'},
                                name: translation.abnormal,
                            },
                            // {type: 'bar', x: [1, 2, 3], y: [2, 5, 3]},
                        ]}
                        layout={ {yaxis: {range: [minTemp-5, maxTemp+5], fixedrange: true}, xaxis: {fixedrange: true}, width: 500, height: 550, title: translation.trendGraph, paper_bgcolor: bgColor, plot_bgcolor: bgColor} }
                        config={ {displayModeBar: false} }
                    />
        </>
    )
}

export default Detail;