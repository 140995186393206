import React, { useEffect } from 'react';
import Plot from 'react-plotly.js';

import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import './Report.css';

function createData(time, temp) {
    return { time, temp };
}

const Graph = (props) => {
    return (
        <div className='graph'>
            <Plot
                data={[
                    {
                        x: [1, 2, 3, 4, 5, 6, 7],
                        y: [28, 28, 27, 27, 28, 28, 28],
                        type: 'scatter',
                        mode: 'lines',
                        marker: { color: '#e6e6ff' },
                        showlegend: false,
                    },
                    {
                        x: [],
                        y: [],
                        type: 'scatter',
                        mode: 'markers',
                        marker: { color: '#3F8EE8' },
                        name: '正常',
                    },
                    // {
                    //     x: [3, 8, 10, 11, 12],
                    //     y: [28, 28, 28, 28, 28],
                    //     type: 'scatter',
                    //     mode: 'markers',
                    //     marker: {color: '#FF3838'},
                    //     name: '異常',
                    // },
                    // {type: 'bar', x: [1, 2, 3], y: [2, 5, 3]},
                ]}
                layout={{ width: 525, height: 350, title: '趨勢變化圖', paper_bgcolor: '#FFFFFF', plot_bgcolor: '#FFFFFF' }}
                divId='graph'
                config={{ staticPlot: true }}
            />
        </div>
    );
};

const Report = (props) => {
    const { reportData } = props;

    const tempSymbol = reportData.tempUnit === 'fahrenheit'? '°F' : '°C';

    const tempData = reportData.detailRowData
        ? reportData.detailRowData.map((row) => {
                return createData(dayjs(row.time).format('YYYY-MM-DD HH:mm'), row.temperature);
            })
        : [];

    const lineColor = reportData.is_normal ? '#4485C6' : '#E64644';

    const duration = tempData.length * 10;
    const durationD = Math.floor(duration / 60 / 24);
    const durationH = Math.floor((duration % (60 * 24)) / 60);
    const durationM = duration % 60;
    const durationAboveThreshold = tempData.filter((row) => row.temp > reportData.upperLimit).length * 10;
    const durationAboveThresholdD = Math.floor(durationAboveThreshold / 60 / 24);
    const durationAboveThresholdH = Math.floor((durationAboveThreshold % (60 * 24)) / 60);
    const durationAboveThresholdM = durationAboveThreshold % 60;
    const durationBelowThreshold = tempData.filter((row) => row.temp < reportData.lowerLimit).length * 10;
    const durationBelowThresholdD = Math.floor(durationBelowThreshold / 60 / 24);
    const durationBelowThresholdH = Math.floor((durationBelowThreshold % (60 * 24)) / 60);
    const durationBelowThresholdM = durationBelowThreshold % 60;
    const durationOutOfThreshold = reportData.timeLimit / 60;
    const durationOutOfThresholdD = Math.floor(durationOutOfThreshold / 60 / 24);
    const durationOutOfThresholdH = Math.floor((durationOutOfThreshold % (60 * 24)) / 60);
    const durationOutOfThresholdM = durationOutOfThreshold % 60;
    const intervalD = Math.floor(reportData.interval / 60 / 60 / 24);
    const intervalH = Math.floor(reportData.interval / 60 / 60) % 24;
    const intervalM = Math.floor(reportData.interval / 60) % 60;
    const maxTemp = Math.max(...tempData.map((row) => row.temp));
    const minTemp = Math.min(...tempData.map((row) => row.temp));
    // const rowCount = reportData.detailRowData ? reportData.detailRowData.length/4 : 0;
    // const tempDataDivided = tempData.reduce((acc, e, i) => (i % 4 ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);
    // fill tempData to multiple of 200
    const tempDataFilled = tempData.concat(Array(200 - (tempData.length % 200)).fill({ time: '', temp: '' }));
    // divide by page and 200 records per page (50 records per column)
    const tempDataDivided = tempDataFilled.reduce((acc, e, i) => (i % 200 ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);
    const tempDataRow = [];
    tempDataDivided.forEach((page, outIndex) => {
        tempDataRow.push([]);
        for (let i = 0; i < 50; i++) {
            tempDataRow[outIndex].push([]);
            tempDataRow[outIndex][i].push(page[i]);
            tempDataRow[outIndex][i].push(page[i + 50]);
            tempDataRow[outIndex][i].push(page[i + 100]);
            tempDataRow[outIndex][i].push(page[i + 150]);
        }
    });
    // const tempDataDivided = tempDataFilled.reduce((acc, e, i) => (i % 50 ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);
    // const tempTranspose = tempDataDivided[0].map((col, i) => tempDataDivided.map(row => row[i]));
    // console.log(tempDataRow);

    return (
        <div className='report' style={{ width: '570pt', margin: '10pt' }}>
            <div style={{ display: 'inline', float: 'left' }}>File created: {dayjs().format('YYYY-MM-DD HH:mm')}</div>
            <div style={{ display: 'inline', float: 'right' }}>Status: {reportData.is_normal ? <font color='blue'>Pass</font> : <font color='red'>Fail</font>}</div>
            <hr style={{ width: '100%' }}></hr>
            <div style={{ width: '100%', height: '60pt', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', backgroundColor: '#080074' }}>
                <p style={{ color: '#FFFFFF', fontSize: '25pt' }}>DATA REPORT</p>
            </div>
            <table style={{ width: '100%', border: '1px black solid', borderCollapse: 'collapse', margin: '10px auto' }}>
                <tbody>
                    <tr>
                        <td>Delivered by:</td>
                        <td>{reportData.shipper}</td>
                    </tr>
                    <tr>
                        <td>Period covered by this report:</td>
                        <td>
                            {reportData.startTime} to {reportData.endTime}
                        </td>
                    </tr>
                    <tr style={{ borderTop: '1px black solid' }}>
                        <td>Time Zone</td>
                        <td>{reportData.timeZone}</td>
                    </tr>
                </tbody>
            </table>

            <hr></hr>
            <table style={{ width: '100%', margin: 'auto' }}>
                <tbody>
                    <tr style={{ backgroundColor: '#5271FF', margin: 0 }}>
                        <th colSpan={4} style={{ height: '20pt', color: '#FFFFFF' }}>
                            Device Information
                        </th>
                    </tr>
                    <tr>
                        <td>Device Model</td>
                        <td>{reportData.model_info?.model}</td>
                        <td>Sensor Type</td>
                        <td>Temperature</td>
                    </tr>
                    <tr>
                        <td>Device ID</td>
                        <td>{reportData.id}</td>
                        <td>Logging Interval</td>
                        <td>
                            {intervalD}D {intervalH}H {intervalM}M
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr></hr>
            <table style={{ width: '100%', margin: 'auto' }}>
                <tbody>
                    <tr style={{ backgroundColor: '#5271FF' }}>
                        <th colSpan={4} style={{ height: '20pt', color: '#FFFFFF' }}>
                            Data Details
                        </th>
                    </tr>
                    <tr>
                        <td>Number of records</td>
                        <td>{reportData.number_of_record}</td>
                        <td>Maximum temperature</td>
                        <td>{maxTemp}{tempSymbol}</td>
                    </tr>
                    <tr>
                        <td>Duration of records</td>
                        <td>{durationD + 'D ' + durationH + 'H ' + durationM + 'M'}</td>
                        <td>Minimum temperature</td>
                        <td>{minTemp}{tempSymbol}</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>Status</td>
                        <td rowSpan={2}>{reportData.is_normal ? <font color='blue'>Pass</font> : <font color='red'>Fail</font>}</td>
                        <td>Duration Over {reportData.upperLimit}{tempSymbol}</td>
                        <td>{durationAboveThresholdD + 'D ' + durationAboveThresholdH + 'H ' + durationAboveThresholdM + 'M'}</td>
                    </tr>
                    <tr>
                        <td>Duration Below {reportData.lowerLimit}{tempSymbol}</td>
                        <td>{durationBelowThresholdD + 'D ' + durationBelowThresholdH + 'H ' + durationBelowThresholdM + 'M'}</td>
                    </tr>
                    <tr>
                        <td>Excursion(Fail) Alarm</td>
                        <td colSpan={3}>
                            {reportData.thresholdType}: Out of {reportData.upperLimit}{tempSymbol} to {reportData.lowerLimit}{tempSymbol} for {durationOutOfThresholdD} D {durationOutOfThresholdH} H {durationOutOfThresholdM} M
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr></hr>
            <table style={{ width: '100%', margin: 'auto' }}>
                <tbody>
                    <tr style={{ backgroundColor: '#5271FF' }}>
                        <th colSpan={1} style={{ height: '20pt', color: '#FFFFFF' }}>
                            Temperature Graph
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <Plot
                                data={[
                                    {
                                        x: tempData.map((row) => row.time),
                                        y: tempData.map((row) => row.temp),
                                        type: 'scatter',
                                        mode: 'lines',
                                        marker: { color: '#000000' },
                                        showlegend: false,
                                    },
                                    {
                                        x: tempData.map((row) => row.time),
                                        y: tempData.map(() => reportData.upperLimit),
                                        type: 'scatter',
                                        mode: 'lines',
                                        marker: { color: '#FF3838' },
                                        line: { dash: 'dash' },
                                        showlegend: false,
                                    },
                                    {
                                        x: tempData.map((row) => row.time),
                                        y: tempData.map(() => reportData.lowerLimit),
                                        type: 'scatter',
                                        mode: 'lines',
                                        marker: { color: '#3F8EE8' },
                                        line: { dash: 'dash' },
                                        showlegend: false,
                                    },
                                    // {
                                    //     x: [3, 8, 10, 11, 12],
                                    //     y: [28, 28, 28, 28, 28],
                                    //     type: 'scatter',
                                    //     mode: 'markers',
                                    //     marker: {color: '#FF3838'},
                                    //     name: '異常',
                                    // },
                                    // {type: 'bar', x: [1, 2, 3], y: [2, 5, 3]},
                                ]}
                                layout={{
                                    xaxis: { tickangle: 0 },
                                    yaxis: { range: [minTemp < reportData.lowerLimit ? minTemp - 5 : reportData.lowerLimit - 5, maxTemp > reportData.upperLimit ? maxTemp + 5 : reportData.upperLimit + 5], zeroline: false },
                                    width: 755,
                                    height: 645,
                                    margin: { b: 100 },
                                    paper_bgcolor: '#FFFFFF',
                                    plot_bgcolor: '#FFFFFF',
                                }}
                                divId='graph'
                                config={{ staticPlot: true }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            {tempDataRow.map((page, pgindex) => {
                return (
                    <div key={pgindex}>
                        <hr style={{ marginTop: '30px' }}></hr>
                        <table key={`table-${pgindex}`} style={{ width: '100%', margin: '10px auto 60px auto' }}>
                            <tbody>
                                <tr key={`page-${pgindex}`} style={{ backgroundColor: '#5271FF' }}>
                                    <th colSpan={12} style={{ height: '20pt', color: '#FFFFFF' }}>
                                        Data Records
                                    </th>
                                </tr>
                                <tr className='subheader' key={`'header'-${pgindex}`}>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>{tempSymbol}</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>{tempSymbol}</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>{tempSymbol}</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>{tempSymbol}</th>
                                </tr>
                                {page.map((row, rindex) => {
                                    return (
                                        <tr key={`record-${pgindex}-${rindex}`}>
                                            {row.map((col, cindex) => {
                                                let color = '#000000';
                                                if (col.temp > reportData.upperLimit) {
                                                    color = '#FF3838';
                                                } else if (col.temp < reportData.lowerLimit) {
                                                    color = '#3F8EE8';
                                                }
                                                return (
                                                    <React.Fragment key={`column-${pgindex}-${rindex}-${cindex}`}>
                                                        <td key={`column-${pgindex}-${rindex}-${cindex}-1`} style={{ color: color }}>
                                                            {col.time.split(' ')[0]}
                                                        </td>
                                                        <td key={`column-${pgindex}-${rindex}-${cindex}-2`} style={{ color: color }}>
                                                            {col.time.split(' ')[1]}
                                                        </td>
                                                        <td key={`column-${pgindex}-${rindex}-${cindex}-3`} style={{ color: color }}>
                                                            {col.temp}
                                                        </td>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                );
            })}
            {/* <tr style={{backgroundColor: '#5271FF'}}>
                    <th colSpan={12} style={{height: '20pt', color: '#FFFFFF'}}>Data Records</th>
                </tr>
                <tr className="subheader">
                    <th>Date</th>
                    <th>Time</th>
                    <th>{tempSymbol}</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>{tempSymbol}</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>{tempSymbol}</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>{tempSymbol}</th>
                </tr>
                {
                    tempDataDivided[0].map((row, index) => {
                        return (
                            <tr key={index}>
                                <td>{row.time.split(' ')[0]}</td>
                                <td>{row.time.split(' ')[1]}</td>
                                <td>{row.temp}</td>
                                <td>{tempDataDivided[1][index] ? tempDataDivided[1][index].time.split(' ')[0] : ''}</td>
                                <td>{tempDataDivided[1][index] ? tempDataDivided[1][index].time.split(' ')[1] : ''}</td>
                                <td>{tempDataDivided[1][index] ? tempDataDivided[1][index].temp : ''}</td>
                                <td>{tempDataDivided[2][index] ? tempDataDivided[2][index].time.split(' ')[0] : ''}</td>
                                <td>{tempDataDivided[2][index] ? tempDataDivided[2][index].time.split(' ')[1] : ''}</td>
                                <td>{tempDataDivided[2][index] ? tempDataDivided[2][index].temp : ''}</td>
                                <td>{tempDataDivided[3][index] ? tempDataDivided[3][index].time.split(' ')[0] : ''}</td>
                                <td>{tempDataDivided[3][index] ? tempDataDivided[3][index].time.split(' ')[1] : ''}</td>
                                <td>{tempDataDivided[3][index] ? tempDataDivided[3][index].temp : ''}</td>
                            </tr>
                        )
                    })

                } */}
        </div>
    );
};

function BasicDocument() {
    const printRef = React.useRef();
    const printRecords = React.useRef();

    const download = async () => {
        const pdf = new jsPDF('portrait', 'pt', 'a4');

        pdf.html(<Report />).then(() => {
            // console.log(document.getElementById("report"));
            pdf.save('report.pdf');
        });
    };

    const createPDF = async () => {
        // const pdf = new jsPDF("p", "pt", [595,842]);

        // const element = <Report/>;
        // console.log(element);

        // const data = renderToString(<Report/>);
        // const graph = renderToString(<Graph/>);
        // const html = renderToStaticMarkup(<Report/>);

        // console.log(data)
        // console.log(graph)
        // console.log(html)

        let input = document.getElementsByClassName('graph')[0];
        // let report = document.getElementsByClassName('report')[0]
        // console.log(input)

        // console.log(typeof(data), 'and', typeof(element), 'and', typeof(input));

        // html2canvas(input).then(canvas => {
        //     console.log('hi')
        //     const imgData = canvas.toDataURL('image/png');
        //     pdf.addImage(imgData, 'PNG', 0, 0);
        //     pdf.save("report.pdf");
        // });

        // pdf.html(report).then(() => {
        //     pdf.save("shipping_label.pdf");
        // });

        const element = printRef.current;
        // console.log(element)
        // const canvas = await
        html2canvas(element, {
            onclone: (document) => {
                document.getElementById('report').style.display = 'block';
            },
        }).then((canvas) => {
            // const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'pt', 'a4');
            const width = 595;
            const pageHeight = 842;
            const imgHeight = (canvas.height * width) / canvas.width;
            // console.log('canvas height width', imgHeight);
            let remain = imgHeight;
            let position = 0;
            remain -= pageHeight;
            pdf.addImage(canvas, 'PNG', 0, position, width, imgHeight, '', 'FAST');
            while (remain >= 0) {
                position = remain - imgHeight;
                // console.log('position', position)
                pdf.addPage();
                pdf.addImage(canvas, 'PNG', 0, position, width, imgHeight, '', 'FAST');
                remain -= pageHeight;
            }
            // pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save('report.pdf');
        });
        // const data = canvas.toDataURL('image/png');
        // const pdf = new jsPDF("portrait", "pt", "a4");
        // pdf.addImage(data, 'PNG', 0, 0, 560, 842);
        // pdf.save("report.pdf");
    };

    return (
        <div>
            <div id='report' ref={printRef} style={{ display: 'block', width: '595pt' }}>
                <Report />
            </div>
            <button onClick={createPDF} type='button'>
                Download
            </button>
        </div>
    );
}

// export default BasicDocument;
export default Report;
