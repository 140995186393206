import { useEffect, useRef, useState, useContext } from 'react';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { updateRecord, updateRecords } from '../../api';
import { convertToCelsius, convertTemperature, convertToSecond, secondToTime } from '../../lib/convert';
import { temperatureContext } from '../../lib/context';
import './Configure.css';

function Config(props) {
    const { deviceId, index, metaData, threshold, interval, translation, ro } = props;

    const tempUnit = useContext(temperatureContext);

    const thresholdConvert = secondToTime(threshold.time_limit);
    const intervalConvert = secondToTime(interval);

    const [saveAlert, setSaveAlert] = useState(false);

    const [rangeType, setRangeType] = useState(threshold.type);
    const [upperUnit, setUpperUnit] = useState(tempUnit);
    const [lowerUnit, setLowerUnit] = useState(tempUnit);
    const [timeUnit, setTimeUnit] = useState(thresholdConvert.unit);
    const [intervalUnit, setIntervalUnit] = useState(intervalConvert.unit);

    const shipmentNumberInput = useRef(null);
    const shipperInput = useRef(null);
    const carrierInput = useRef(null);
    const consigneeInput = useRef(null);
    const startLocationInput = useRef(null);
    const endLocationInput = useRef(null);
    const noteInput = useRef(null);
    const upLimitInput = useRef(null);
    const lowLimitInput = useRef(null);
    const timeLimitInput = useRef(null);
    const intervalInput = useRef(null);
    const emailInput = useRef(null);

    useEffect(() => {
        setUpperUnit(tempUnit);
        setLowerUnit(tempUnit);
    }, [tempUnit]);

    const handleCloseSaveAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaveAlert(false);
    };

    const handleSaveAll = () => {
        // console.log('save all click')
        let newConfig = {
            shipment_number: shipmentNumberInput.current.value,
            shipper_name: shipperInput.current.value,
            carrier_name: carrierInput.current.value,
            consignee_name: consigneeInput.current.value,
            start_location: startLocationInput.current.value,
            end_location: endLocationInput.current.value,
            note: noteInput.current.value,
            email: emailInput.current.value,
            upper_limit: convertToCelsius(parseFloat(upLimitInput.current.value), upperUnit),
            lower_limit: convertToCelsius(parseFloat(lowLimitInput.current.value), lowerUnit),
            time_limit: convertToSecond(parseInt(timeLimitInput.current.value), timeUnit),
            interval: convertToSecond(parseInt(intervalInput.current.value), intervalUnit),
            type: rangeType,
        };

        // console.log(newConfig);
        updateRecords([deviceId], [index], newConfig);
        setSaveAlert(true);

        // const infoForm = document.getElementById('infoForm');
        // const formData = new FormData(infoForm);
        // const data = {};
        // for (let [key, value] of formData.entries()) {
        //     data[key] = value;
        // }
        // console.log(data);
    };

    return (
        <div className='modal__container'>
            <Snackbar open={saveAlert} autoHideDuration={6000} onClose={handleCloseSaveAlert}>
                <Alert onClose={handleCloseSaveAlert} severity='success'>
                    Save successfully!
                </Alert>
            </Snackbar>
            <form className='modal__form__display' id='infoForm'>
                <div className='modal__block'>
                    <div className='title'>{translation.information}</div>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.shipmentNumber}</label>
                        <div className='vline'></div>
                        <input name='shipmentNumber' className='modal__form__item__input' type='text' placeholder='1234567890' defaultValue={metaData.shipment_number} ref={shipmentNumberInput} />
                    </div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.shipper}</label>
                        <div className='vline'></div>
                        <input name='shipperName' className='modal__form__item__input' type='text' placeholder='XXXXXX' defaultValue={metaData.shipper_name} ref={shipperInput} />
                    </div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.carrier}</label>
                        <div className='vline'></div>
                        <input name='carrier' className='modal__form__item__input' type='text' placeholder='XXXXXX' defaultValue={metaData.carrier_name} ref={carrierInput} />
                    </div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.consignee}</label>
                        <div className='vline'></div>
                        <input name='consignee' className='modal__form__item__input' type='text' placeholder='XXXXXX' defaultValue={metaData.consignee_name} ref={consigneeInput} />
                    </div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.startLocation}</label>
                        <div className='vline'></div>
                        <input name='startLocation' className='modal__form__item__input' type='text' placeholder='XXXXXX' defaultValue={metaData.start_location} ref={startLocationInput} />
                    </div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.endLocation}</label>
                        <div className='vline'></div>
                        <input name='endLocation' className='modal__form__item__input' type='text' placeholder='XXXXXX' defaultValue={metaData.end_location} ref={endLocationInput} />
                    </div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.note}</label>
                        <div className='vline'></div>
                        <input name='note' className='modal__form__item__input note' type='text' placeholder='XXXXXX' defaultValue={metaData.note} ref={noteInput} />
                    </div>
                </div>
                <div className='modal__block'>
                    <div className='title'>{translation.configure}</div>
                    <div className='modal__form__item__title'>{translation.failAlarm}</div>

                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.excursionType}</label>
                        <div className='vline'></div>
                        <div className='rangebar__container__item excursion' style={{ width: '30%' }}>
                            <button disabled={ro} id='continuous' type='button' className={rangeType === 'continuous' ? 'active' : ''} onClick={() => setRangeType('continuous')}>
                                {translation.singleEvent}
                            </button>
                        </div>
                        <div className='rangebar__container__item excursion' style={{ width: '30%' }}>
                            <button disabled={ro} id='cumulative' type='button' className={rangeType === 'cumulative' ? 'active' : ''} onClick={() => setRangeType('cumulative')}>
                                {translation.cumulative}
                            </button>
                        </div>
                    </div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.upperLimit}</label>
                        <div className='vline'></div>
                        <input disabled={ro} name='upLimit' className='modal__form__item__input alarm__config' type='text' placeholder={convertTemperature(8, upperUnit)} defaultValue={threshold.upper_limit} ref={upLimitInput} />
                        <button disabled={ro} type='button' className={upperUnit === 'celsius' ? 'active config__button' : 'config__button'} onClick={() => setUpperUnit('celsius')}>
                            °C
                        </button>
                        <button disabled={true} type='button' className='config__button hidden'></button>
                        <button disabled={true} type='button' className='config__button hidden'></button>
                        <button disabled={ro} type='button' className={upperUnit === 'fahrenheit' ? 'active config__button' : 'config__button'} onClick={() => setUpperUnit('fahrenheit')}>
                            °F
                        </button>
                    </div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.lowerLimit}</label>
                        <div className='vline'></div>
                        <input disabled={ro} name='lowLimit' className='modal__form__item__input alarm__config' type='text' placeholder={convertTemperature(2, lowerUnit)} defaultValue={threshold.lower_limit} ref={lowLimitInput} />
                        <button disabled={ro} type='button' className={lowerUnit === 'celsius' ? 'active config__button' : 'config__button'} onClick={() => setLowerUnit('celsius')}>
                            °C
                        </button>
                        <button disabled={true} type='button' className='config__button hidden'></button>
                        <button disabled={true} type='button' className='config__button hidden'></button>
                        <button disabled={ro} type='button' className={lowerUnit === 'fahrenheit' ? 'active config__button' : 'config__button'} onClick={() => setLowerUnit('fahrenheit')}>
                            °F
                        </button>
                    </div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.duration}</label>
                        <div className='vline'></div>
                        <input disabled={ro} name='timeLimit' className='modal__form__item__input alarm__config' type='text' placeholder='10' defaultValue={thresholdConvert.value} ref={timeLimitInput} />
                        <button disabled={ro} type='button' className={timeUnit === 'second' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('second')}>
                            {translation.S}
                        </button>
                        <button disabled={ro} type='button' className={timeUnit === 'minute' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('minute')}>
                            {translation.M}
                        </button>
                        <button disabled={ro} type='button' className={timeUnit === 'hour' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('hour')}>
                            {translation.H}
                        </button>
                        <button disabled={ro} type='button' className={timeUnit === 'day' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('day')}>
                            {translation.D}
                        </button>
                    </div>
                    <div className='modal__form__item__title'>{translation.dataLogging}</div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.loggingInterval}</label>
                        <div className='vline'></div>
                        <input disabled={true} name='interval' className='modal__form__item__input alarm__config' type='text' placeholder='10' defaultValue={intervalConvert.value} ref={intervalInput} />
                        <button disabled={true} type='button' className={intervalUnit === 'second' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('second')}>
                            {translation.S}
                        </button>
                        <button disabled={true} type='button' className={intervalUnit === 'minute' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('minute')}>
                            {translation.M}
                        </button>
                        <button disabled={true} type='button' className={intervalUnit === 'hour' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('hour')}>
                            {translation.H}
                        </button>
                        <button disabled={true} type='button' className={intervalUnit === 'day' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('day')}>
                            {translation.D}
                        </button>
                    </div>
                    <div className='modal__form__item__title'>{translation.alarmNotification}</div>
                    <hr className='hline'></hr>
                    <div className='modal__form__item'>
                        <label className='modal__form__item__label'>{translation.email}</label>
                        <div className='vline'></div>
                        <input disabled={ro} name='email' className='modal__form__item__input' type='text' placeholder='XXXXXX' defaultValue={metaData.email} ref={emailInput} />
                    </div>
                </div>
            </form>
            <div className='button__container'>
                <button className='save__all' onClick={handleSaveAll}>
                    {translation.saveAll}
                </button>
            </div>
        </div>
    );
}

export default Config;
